<!--
 * @Description: 选中场景列表
 * @Author: ChenXueLin
 * @Date: 2021-10-12 11:09:47
 * @LastEditTime: 2022-07-01 09:22:47
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-dialog
      v-dialogDrag
      title="已选安装场景"
      :visible="sceneTableVisible"
      width="900px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleCloseScene"
      custom-class="install-scene-dialog"
    >
      <div class="searchForm">
        <el-input placeholder="输入场景名称搜索" class="search-input">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="btn-box">
        <el-button type="primary" @click="addScene">添加场景</el-button>
        <el-button type="primary">删除场景</el-button>
        <el-button type="primary">导入场景</el-button>
      </div>
      <el-table
        border
        height="300px"
        :data="installData"
        highlight-current-row
        @selection-change="handleSelect"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in sceneColumn"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="column.fieldName === 'id'">
              <e6-vr-select
                :data="typeList"
                placeholder="任务单二级分类"
                title="任务单二级分类"
                clearable
              ></e6-vr-select>
            </div>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleCloseScene">取消</el-button>
        <el-button type="primary">确定</el-button>
      </div>
    </el-dialog>
    <!-- 添加场景 -->
    <select-scene
      :selectSceneDialog="selectSceneDialog"
      @handleCloseSelectScene="handleCloseDialog"
    ></select-scene>
  </div>
</template>

<script>
import base from "@/mixins/base";
import selectScene from "@/components/workOrderManage/selectScene.vue";
export default {
  name: "",
  components: {
    selectScene
  },
  data() {
    return {
      sceneColumn: [
        {
          fieldName: "a",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "b",
          display: true,
          fieldLabel: "场景类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "c",
          display: true,
          fieldLabel: "安装组合",
          width: 220,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      selectSceneDialog: false, //添加场景
      installData: [
        {
          a: "qqq",
          b: "eee",
          c: "ggg"
        }
      ]
    };
  },
  props: {
    sceneTableVisible: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    //添加场景
    addScene() {
      this.selectSceneDialog = true;
    },
    //选中数据
    handleSelect(val) {
      console.log(val);
    },
    //关闭弹框
    handleCloseScene() {
      this.$emit("handleCloseScene", "sceneTableVisible");
    },
    //关闭添加场景弹框
    handleCloseDialog(visibleName) {
      this[visibleName] = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.install-scene-dialog .el-dialog__body {
  padding: 20px;
  box-sizing: border-box;
}
.btn-box {
  margin-bottom: 15px;
  margin-top: 15px;
}
.search-input {
  width: 300px;
}
</style>
