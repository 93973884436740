<!--
 * @Description: 关联订单弹框
 * @Author: ChenXueLin
 * @Date: 2021-08-24 13:59:18
 * @LastEditTime: 2022-07-01 09:22:13
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 关联订单 start -->
  <el-dialog
    v-dialogDrag
    title="关联订单"
    :visible.sync="bindOrderDialog"
    width="500px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    custom-class="bind-order-dialog"
    :before-close="handleCloseBIndOrder"
  >
    <el-form ref="contactsForm" label-width="88px">
      <el-form-item label="订单编号">
        <e6-vr-select
          v-model="orderNo"
          :data="typeList"
          placeholder="订单编号"
          title=""
          clearable
        ></e6-vr-select>
      </el-form-item>
      <el-form-item prop="countryCode" label="订单号">
        123456789
      </el-form-item>
      <el-form-item prop="countryCode" label="下单日期">
        123456789
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleCloseBIndOrder">取消</el-button>
      <el-button type="primary">确定</el-button>
    </div>
  </el-dialog>
  <!-- 关联订单 end -->
</template>

<script>
import base from "@/mixins/base";
export default {
  name: "bindOrder",
  components: {},
  data() {
    return {
      orderNo: "",
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ]
    };
  },
  props: {
    bindOrderDialog: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    handleCloseBIndOrder() {
      this.$emit("handleCloseBIndOrder");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .bind-order-dialog {
  .el-dialog__body {
    padding: 20px 18px 2px 18px;
    .el-input {
      width: 230px;
    }
  }
}
</style>
