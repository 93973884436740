<!--
 * @Description: 加装任务单
 * @Author: ChenXueLin
 * @Date: 2021-10-12 08:54:37
 * @LastEditTime: 2022-01-12 17:32:30
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view">
    <section class="edit-wrapper">
      <div class="edit-wrapper__body">
        <!-- 基本信息 start -->
        <template>
          <div class="primaryTitle">加装任务单</div>
          <div class="edit-title">基本信息</div>
          <div class="edit-content">
            <el-form
              ref="distributionInfoForm"
              class="form-content"
              label-position="right"
              :inline="true"
              label-width="120px"
            >
              <el-form-item label="客户名称">
                <el-input placeholder="客户名称"></el-input>
              </el-form-item>
              <el-form-item label="联系人">
                <el-input placeholder="联系人"></el-input>
              </el-form-item>

              <el-form-item label="联系电话">
                <el-input placeholder="联系电话"></el-input>
              </el-form-item>
              <el-form-item label="联系地址" prop="expressCorpId">
                <div class="address-box">
                  <e6-city
                    :format="['区县']"
                    :clearable="false"
                    :placeholder="'省/市/县'"
                  ></e6-city>
                  <!--  @selected="citySelect" -->
                  <el-input placeholder="详细地址"></el-input>
                  <!-- <el-button type="text" style="margin-left: 30px"
                    >选择已有地址</el-button
                  > -->
                </div>
              </el-form-item>
              <el-form-item label="要求完成时间" prop="expressNo">
                <el-date-picker
                  type="datetime"
                  placeholder="选择日期"
                  value-format="timestamp"
                  popper-class="special"
                  :picker-options="pickerOptions"
                ></el-date-picker>
                <!-- v-model="value" -->
              </el-form-item>
              <el-form-item label="备注" prop="remarks">
                <el-input
                  placeholder="请输入备注"
                  type="textarea"
                  :rows="3"
                  style="width: 500px"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="select-form-box">
            <el-form ref="selectForm" :inline="true" label-width="120px">
              <el-form-item label="计费方式">
                <e6-vr-select
                  v-model="typeId"
                  :data="typeList"
                  placeholder="计费方式"
                  title="计费方式"
                  clearable
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="业务类型">
                <e6-vr-select
                  v-model="typeId"
                  :data="typeList"
                  placeholder="业务类型"
                  title="业务类型"
                  clearable
                ></e6-vr-select>
              </el-form-item>
            </el-form>
          </div>
        </template>
        <!-- 基本信息 end -->
        <!-- 关联订单table   start -->
        <template>
          <div class="edit-title">任务项</div>
          <div class="edit-content" style="margin-bottom: 20px">
            <div class="associated-info">
              <div class="left-info">
                <i class="el-icon-document"></i>
                <span
                  style="
                        font-weight: 600;
                        margin-left: 5px;
                        margin-right: 5px;
                      "
                >
                  已关联订单:
                </span>
                <span>123456</span>
              </div>
              <div class="right-button">
                <!-- <el-button type="primary" @click="createTask"
                  >添加商品</el-button
                > -->
                <el-button type="primary" @click="bindOrder"
                  >关联订单</el-button
                >
              </div>
            </div>
            <!-- 关联订单的table  start -->
            <el-table
              :data="bindOrderData"
              highlight-current-row
              height="200px"
            >
              <el-table-column
                show-overflow-tooltip
                v-for="(column, index) in bindOrderColumn"
                :key="index"
                :prop="column.fieldName"
                :label="column.fieldLabel"
                :min-width="column.width"
                :fixed="column.fixed"
                :align="column.align"
                header-align="center"
              ></el-table-column>
            </el-table>
            <!-- 关联订单的table  end -->
          </div>
        </template>
        <!-- 场景设置  start -->
        <template>
          <el-table :data="sceneData" highlight-current-row height="120px">
            <el-table-column
              show-overflow-tooltip
              prop="name"
              label="场景名称"
              align="center"
              header-align="center"
            >
              <el-button type="primary" @click="handleSelectScene"
                >选择场景</el-button
              >
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="number"
              label="数量"
              align="center"
              header-align="center"
            >
              <el-input placeholder="安装数量" style="width:100px"></el-input>
            </el-table-column>
          </el-table>
        </template>
        <!-- 场景设置  end -->
        <!-- 设备列表  start -->
        <template>
          <el-button
            type="primary"
            @click="addEquip"
            style="margin:10px;float:right"
            >添加设备</el-button
          >
          <el-table :data="equipList" highlight-current-row height="200px">
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in equipColumn"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
              <template slot-scope="{ row }">
                <span v-if="column.fieldName === 'installNumber'">
                  <el-input
                    placeholder="安装数量"
                    style="width:100px"
                    v-model="row.count"
                  ></el-input>
                </span>
                <span v-else>{{ row[column.fieldName] }}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              align="center"
              label="操作"
              width="60"
            >
              <template slot-scope="scope">
                <e6-td-operate
                  :data="getOperateList(scope.row)"
                  @command="handleOperate($event, scope)"
                ></e6-td-operate>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <!-- 设备列表  end -->
      </div>
      <div class="edit-wrapper__footer">
        <el-button class="cancel">取消</el-button>
        <el-button type="primary">确定</el-button>
      </div>
    </section>
    <!-- 已选场景弹框 -->
    <scene-table
      :sceneTableVisible="sceneTableVisible"
      @handleCloseScene="handleClose"
    ></scene-table>
    <!-- 关联订单弹框 -->
    <bindOrder
      :bindOrderDialog="bindOrderDialog"
      @handleCloseBIndOrder="handleClose"
    ></bindOrder>

    <!-- 添加商品弹框 -->
    <add-goods
      :addGoodsDialog="addGoodsDialog"
      @handleCloseAddGoods="handleClose"
    >
    </add-goods>

    <!-- 选择设备弹框 -->
    <select-equipment
      :selectEquipmentDialog="selectEquipmentDialog"
      @handlePush="handlePush"
      @handleClose="handleClose"
    ></select-equipment>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import sceneTable from "@/components/workOrderManage/sceneTable.vue";
import bindOrder from "@/components/workOrderManage/bindOrder.vue";
import addGoods from "@/components/workOrderManage/addGoods.vue";
import SelectEquipment from "@/components/workOrderManage/selectEquipment.vue";

export default {
  name: "",
  components: {
    sceneTable,
    bindOrder,
    addGoods,
    SelectEquipment
  },
  data() {
    return {
      typeId: "",
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      bindOrderColumn: [
        {
          fieldName: "id",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "name",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "type",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "size",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "type",
          display: true,
          fieldLabel: "类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "count",
          display: true,
          fieldLabel: "安装总数/可安装数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //关联订单表头
      bindOrderData: [
        {
          name: "1111",
          type: "台",
          size: "100",
          count: 100
        }
      ], //关联订单table
      sceneData: [
        {
          name: "1111",
          type: "台",
          size: "100",
          count: 100
        }
      ],
      /***  加装设备编辑表格 */
      equipColumn: [
        {
          fieldName: "productName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "classify",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invoiceName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "productType",
          display: true,
          fieldLabel: "类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installNumber",
          display: true,
          fieldLabel: "安装数量",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      equipList: [
        {
          productName: "摄像头",
          classify: "485数字温度采集器",
          invoiceName: "485数字温度采集器",
          productType: "配件"
        },
        {
          productName: "摄像头",
          classify: "485数字温度采集器",
          invoiceName: "485数字温度采集器",
          productType: "配件"
        },
        {
          productName: "摄像头",
          classify: "485数字温度采集器",
          invoiceName: "485数字温度采集器",
          productType: "配件"
        }
      ],
      /**选择设备弹框 */
      selectEquipmentDialog: false,
      /**   关联订单弹框 */
      // 关联订单
      bindOrderDialog: false, //关联订单弹框是否显示
      // 设置安装场景
      installSceneDialog: false,
      //添加商品
      addGoodsDialog: false,
      // 选择场景
      selectSceneDialog: false,
      sceneTableVisible: false //已选安装场景弹框
    };
  },
  mixins: [base, listPage, listPageReszie],
  methods: {
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "删除",
          id: 1
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, scope) {
      if (val.id == 1) {
        this.handleDelete(scope.$index);
      }
    },
    /**** 添加配件 */
    //点击添加设备按钮
    addEquip() {
      this.selectEquipmentDialog = true;
    },
    // 点击确定添加配件
    handlePush(data) {
      this.equipList = this.equipList.concat(data);
      console.log(this.equipList);
      this.selectEquipmentDialog = false;
    },
    //删除配件
    handleDelete(index) {
      this.equipList.splice(index, 1);
    },

    //添加商品
    addGoods() {
      this.addGoodsDialog = true;
    },
    //添加任务项
    createTask() {},
    /**安装设置 */

    /** 关联订单*/
    //点击关联订单
    bindOrder() {
      this.bindOrderDialog = true;
    },

    //选择场景
    handleSelectScene() {
      this.sceneTableVisible = true;
    },
    // 关闭弹框
    handleClose(visibleName) {
      this[visibleName] = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
  .el-table {
    & tr th > .cell {
      height: 38px;
      line-height: 38px;
      color: #898c91;
      background-color: #ffffff;
    }
    .el-table__body .el-table__row:nth-child(2n) td {
      background-color: #ffffff;
    }
  }
}
.select-form-box {
  width: 100%;
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
  border-top: 1px solid #ebeef5;
}
.form-content {
  .el-input {
    width: 280px;
  }
}

.edit-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  .operate-action {
    height: 38px;
  }
}
.address-box {
  display: flex;
  align-items: center;
}
.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  .left-info {
    display: flex;
    align-items: center;
    & [class^="el-icon"] {
      font-size: 30px;
      color: #46bfea;
      cursor: pointer;
    }
  }
}
</style>
