<!--
 * @Description: 添加商品弹框
 * @Author: ChenXueLin
 * @Date: 2021-08-24 14:17:00
 * @LastEditTime: 2022-07-01 09:21:58
 * @LastEditors: ChenXueLin
-->

<template>
  <el-dialog
    v-dialogDrag
    title="添加商品"
    :visible="addGoodsDialog"
    width="1000px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleCloseaddGoods"
    custom-class="install-scene-dialog"
  >
    <div class="add-good-content">
      <!-- 左侧树 start -->
      <section class="tree">
        <e6-tree
          v-model="selected"
          :list="activityData"
          :is-check="true"
          :is-open="true"
          issingleleaf
          ref="e6tree1"
          :selectAndCheck="true"
        ></e6-tree>
      </section>
      <!-- 左侧树 end -->
      <section class="table">
        <el-table
          border
          :data="installData"
          highlight-current-row
          ref="selectTable"
          @selection-change="handleSelectionChange"
          height="350px"
        >
          <el-table-column
            type="selection"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in orderDataColumn"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleCloseaddGoods">取消</el-button>
      <el-button type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "",
  components: {},
  data() {
    return {
      orderDataColumn: [
        {
          fieldName: "id",
          display: true,
          fieldLabel: "商品名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "id",
          display: true,
          fieldLabel: "商品分类",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "id",
          display: true,
          fieldLabel: "规格",
          width: 60,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "id",
          display: true,
          fieldLabel: "类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      installData: [],
      selected: [6, 7],
      activityData: [
        {
          id: 1,
          pid: 0,
          label: "根节点",
          disabled: true,
          children: [
            {
              id: 2,
              pid: 1,
              label: "支节点",
              disabled: true,
              children: [
                { id: 4, pid: 2, label: "叶子节点", disabled: false },
                { id: 5, pid: 2, label: "叶子节点", disabled: false }
              ]
            },
            {
              id: 3,
              pid: 1,
              label: "支节点",
              disabled: true,
              children: [
                { id: 6, pid: 3, label: "叶子节点", disabled: false },
                { id: 7, pid: 3, label: "叶子节点", disabled: false },
                { id: 8, pid: 3, label: "叶子节点", disabled: false },
                { id: 9, pid: 3, label: "叶子节点", disabled: false }
              ]
            }
          ]
        }
      ]
    };
  },
  props: {
    addGoodsDialog: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {
    //关闭弹框
    handleCloseaddGoods() {
      this.$emit("handleCloseAddGoods");
    },
    handleSelectionChange(column) {
      console.log(column, "167==");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.install-scene-dialog .el-dialog__body {
  padding: 20px;
  box-sizing: border-box;
}
.add-good-content {
  display: flex;
}
.tree {
  width: 230px;
  height: 100%;
}
.table {
  width: calc(100% - 230px);
  height: 100%;
  border-left: 1px solid #edf0f5;
}
</style>
