<!--
 * @Description: 选择设备弹框
 * @Author: ChenXueLin
 * @Date: 2021-10-12 09:44:15
 * @LastEditTime: 2022-07-01 09:24:57
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="选择设备"
    :visible="selectEquipmentDialog"
    width="900px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleCloseScene"
    custom-class="install-scene-dialog"
  >
    <el-table
      border
      height="300px"
      ref="multipleTable"
      :data="equipData"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in equipColumnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
    </el-table>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleCloseScene">取消</el-button>
      <el-button type="primary" @click="handlePush">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
export default {
  name: "",
  components: {},
  data() {
    return {
      //设备表头
      equipColumnData: [
        {
          fieldName: "productName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "classify",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invoiceName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "productType",
          display: true,
          fieldLabel: "类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      //设备table数据
      equipData: [
        {
          productName: "摄像头",
          classify: "485数字温度采集器",
          invoiceName: "485数字温度采集器",
          productType: "配件"
        },
        {
          productName: "摄像头",
          classify: "485数字温度采集器",
          invoiceName: "485数字温度采集器",
          productType: "配件"
        },
        {
          productName: "摄像头",
          classify: "485数字温度采集器",
          invoiceName: "485数字温度采集器",
          productType: "配件"
        }
      ],
      //选中得设备
      multipleSelection: []
    };
  },
  props: {
    selectEquipmentDialog: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    //点击确定
    handlePush() {
      this.$emit("handlePush", this.multipleSelection);
      this.$refs.multipleTable.clearSelection();
    },
    //关闭弹框
    handleCloseScene() {
      this.$emit("handleClose", "selectEquipmentDialog");
    },
    //选中得数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.install-scene-dialog .el-dialog__body {
  padding: 20px;
  box-sizing: border-box;
}
</style>
